import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAssetsUrl, getTourId } from '../../config/constants';

import { faPhone } from '@fortawesome/fontawesome-free-solid';
import { faArrowAltCircleLeft, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import DropdownRc from '../dropdown';
import { CurrencyContext } from '../../contexts/currencyContext';

const Header = () => {
  const backClick = () => {
    document.dispatchEvent(new CustomEvent('eventHeaderClick', { bubbles: true }));
  };
  const location = useLocation();

  const { currency, setCurrency } = useContext(CurrencyContext);

  const [showMenu, setShowMenu] = useState(false);

  return (
    <header id='main-header'>
      <div className='container'>
        <div className='header-wrap'>
          <div className='header-contacts'>

            {(location.pathname !== '/' || getTourId()) && <button className='header__button' onClick={backClick}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </button>}
          </div>
          <div className='header-logo'>
            <Link to='/' className='main-logo'>
              <img src={`${getAssetsUrl()}/logo-dubaitickets.png`} alt='Logo' />
            </Link>
          </div>

          <div className={`header-icon ${showMenu ? 'show-mobile-menu' : ''}`}>
            {/* <div className='header-select-currency-wrap'>
              <DropdownRc
                options={[{ label: 'USD', value: 'USD' }, { label: 'AED', value: 'AED' }]}
                value={currency}
                onChange={(val) => setCurrency(val.value)}
              />
            </div> */}
            <a href='https://api.whatsapp.com/send?phone=%2B971528996301'>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a className='header-phone_desktop' href='tel:+971528996301'>
              <FontAwesomeIcon icon={faPhone as IconProp} />
            </a>
            <a href='mailto:web@dubairelaxtours.com'>
              <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />
            </a>
            <div className='header-mobile-toggler' onClick={() => setShowMenu(prev => !prev)}>
              <span></span><span></span><span></span>
            </div>
            <div className='header-inner--right'>
              <div className='header-icon--right'>
                <div className='header-select-currency-wrap'>
                  <DropdownRc
                    options={[{ label: 'USD', value: 'USD' }, { label: 'AED', value: 'AED' }]}
                    value={currency}
                    onChange={(val) => setCurrency(val.value)}
                  />
                </div>
                <div className='mobile-menu-buttons'>
                  <a href='https://api.whatsapp.com/send?phone=%2B971528996301'>
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                  <a className='header-phone_tablet' href='tel:+971528996301'>
                    <FontAwesomeIcon icon={faPhone as IconProp} />
                  </a>
                  <a href='mailto:web@dubairelaxtours.com'>
                    <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </header>
  );
};

export default Header;
