import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { paymentApi } from "../../lib/api";
import { modulbankCallBackUrl } from "../../config/constants";
import { round } from '../../lib/utils';
interface ModulBankBooking {
    id: number
    guid: string
    date: string
    tourName: string
    offerName: string
    guestName: string
    email: string
    hotelName: string
    roomNumber: string
    phone: string
    numAdults: number
    numChildren: number
    numInfants: number
    numFoc: number
    isPayable: boolean
    isSupplierVoucher: boolean
    total: number
    totalDue: number
    totalVat: number
    totalCollection: number
    totalPayable: number
    totalPayableUsd: number
    pricePerAdult: number
    pricePerChild: number
    pricePerTrip: number
}
type Props = {
    booking: ModulBankBooking;
    amount: number;
    amountUsd: number;
    convRate: number;
    ticketType: string;
    isValid: boolean;
    modulbankId: string
    modulbankSuccessUrl: string
    modulbankTest?: string
    legalInfoPaymentProcessUrl: string
};

type ModulbankRequestItem = {fieldName: string, value: string}
class ModulbankReceiptItem {
    constructor(name: string, quantity: number, price: number) {
        this.name = name
        this.quantitiy = quantity
        this.price = price
    }
    name: string = ""
    quantitiy: number = 0
    price: number = 0
    discount_sum?: number = 0
    sno: string = "usn_income"
    payment_object: string = "service"
    payment_method: string = "full_payment"
    vat: string = "none"
}
class ModulBankReceipt {
    constructor(booking: ModulBankBooking, amount: number) {
        let offerName = booking.offerName
        if(booking.numAdults>0) {offerName += `, ${booking.numAdults} взр.`}
        if(booking.numChildren>0) {offerName += `, ${booking.numChildren} дет.`}

        this.items.push(new ModulbankReceiptItem(offerName, 1, amount))

        // if(booking.pricePerAdult>0){
        // }
        // if(booking.pricePerChild>0){
        //     this.items.push(new ModulbankReceiptItem(booking.offerName + ", дет.", booking.numChildren, booking.pricePerChild))
        // }
        // if(booking.pricePerTrip>0){
        //     this.items.push(new ModulbankReceiptItem(booking.offerName, 1, booking.pricePerTrip))
        // }
    }
    items: ModulbankReceiptItem[] = []
    get json() {
        return JSON.stringify(this.items)
    }
}

class ModulbankRequest {
    values: ModulbankRequestItem[] = []        
    constructor(merchantId: string, successUrl: string,callbackUrl: string, testing: string, booking: ModulBankBooking, amount: number, ticketType: string) {
        this.values.push({fieldName:"client_email", value: booking.email})
        this.values.push({fieldName:"client_name", value: booking.guestName})
        this.values.push({fieldName:"client_phone", value: ""})
        this.values.push({fieldName:"description", value: ticketType})
        this.values.push({fieldName:"merchant", value: merchantId})
        this.values.push({fieldName:"order_id", value: booking.id.toString()})
        this.values.push({fieldName:"receipt_contact", value: booking.email})
        const receiptItems = new ModulBankReceipt(booking, amount)        
        console.log(receiptItems)
        // this.values.push({fieldName:"receipt_items", value:receiptItems.json})
        this.values.push({fieldName:"salt", value:booking.guid})
        this.values.push({fieldName:"success_url", value:successUrl})
        this.values.push({fieldName:"callback_url", value:callbackUrl})
        this.values.push({fieldName:"testing", value:testing})
        this.values.push({fieldName:"meta", value:booking.guid})

        
    }
    async signatureFields(amount: number, amountCard: number, unixTimestamp: string) {        
        const values = [
            {fieldName:"amount", value: amount.toString()}, 
            {fieldName:"amountCard", value: amountCard.toString()}
        ]
        .concat(this.values.concat({fieldName:"unix_timestamp", value:unixTimestamp}))
        return values;
    }  
}
const PaymentModulbank = ({
    booking,     
    amount, 
    amountUsd,
    convRate,
    ticketType, 
    isValid, 
    modulbankId, 
    modulbankSuccessUrl,
    modulbankTest, 
    legalInfoPaymentProcessUrl
}: Props) => {
    const merchantId = modulbankId
    const successUrl = modulbankSuccessUrl
    const isTest = modulbankTest || "0"
    const [signature, setSignature] = useState<{sbp: string, card: string}>({sbp:"", card:""})
    // const signature = 
    useEffect(() => {
        if(!merchantId) {console.log("Modulbank payment configuration is incorrect")}
    }, [merchantId])
    const request = useMemo(() => new ModulbankRequest(merchantId, successUrl, modulbankCallBackUrl, isTest, booking, amount, ticketType), [merchantId, successUrl, isTest, booking, amount, ticketType])
    const unixTimestamp = useMemo(() =>  (Date.now()/1000).toFixed(), []);
    const amountCard = useMemo(() => round(amount * 1.02), [amount])
    const getSignature = useCallback(async (amount: number, amountCard: number) => {
        const values = await request.signatureFields(amount, amountCard, unixTimestamp)
        const s = await paymentApi.getRuPaymentSignature(values)
        setSignature(s) 
    }, [request, unixTimestamp]);
    useEffect(() => {
        getSignature(amount, amountCard)
    }, [amount, amountCard, getSignature, unixTimestamp])
    const qrFormRef = useRef<HTMLFormElement>(null)
    const cardFormRef = useRef<HTMLFormElement>(null)
    const setPaymentDetails = async (paymentType: string, amount: number) => {
        const details = 
        {
            id: booking.id, 
            guid:booking.guid, 
            amount,
            signature: signature.card,
            amountUsd: amountUsd,
            paymentType,
            convRate: convRate
        }
        const res = await paymentApi.setRuPaymentDetails(details)
        return res
    }
    const handleQrFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        const res = await setPaymentDetails("sbp", amount)
        if(!res){
            alert("Не удалось задать параметры оплаты. Попробуйте еще раз")
        }
        qrFormRef.current?.submit()
    }
    const handleCardFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        const res = await setPaymentDetails("card", amountCard)
        if(!res){
            alert("Не удалось задать параметры оплаты. Попробуйте еще раз")     
            return       
        }
        cardFormRef.current?.submit()
    }
    
    return (merchantId) 
    ?(
        <>        
            <form onSubmit={handleQrFormSubmit} ref={qrFormRef} method="POST" action="https://pay.modulbank.ru/pay" id='payment-form' >
                <input hidden type='text' name='amount' value={amount}/>                
                {request.values.map(x=> <input hidden type="text" name={x.fieldName} value={x.value}/>)}              
                <input hidden type='text' name='show_payment_methods' value={'["sbp"]'}/>
                <input hidden type="text" name="unix_timestamp" value={unixTimestamp}/>
                <input hidden type="text" name="signature" value={signature.sbp}/>
                <div className="payment-button-container">
                    <button disabled={!isValid} className="button button_fullWidth button_blue button_medium">Оплатить через СБП {amount} руб.</button>
                </div>                
            </form>
            <form onSubmit={handleCardFormSubmit} ref={cardFormRef} method="POST" action="https://pay.modulbank.ru/pay" id='payment-form-card' >
                <input hidden type='text' name='amount' value={amountCard}/>                                
                {request.values.map(x=> <input hidden type="text" name={x.fieldName} value={x.value}/>)}             
                <input hidden type='text' name='show_payment_methods' value={'["card"]'}/>
                <input hidden type="text" name="unix_timestamp" value={unixTimestamp}/>
                <input hidden type="text" name="signature" value={signature.card}/>              
                <div className="payment-button-container">
                    <button disabled={!isValid} className="button button_fullWidth button_blue button_medium">Оплатить картой РФ {amountCard} руб. (+2%)</button>
                </div>
            </form>
            
            <ul className='checkout-summary-list'>
                <li>
                    <Link to={legalInfoPaymentProcessUrl}>Порядок проведения оплаты и возвратов по банковским картам</Link>
                </li>
            </ul>
        </>
    )
    : <p>"Не задана конфигурация платежного шлюза"</p>
}
export default PaymentModulbank;
